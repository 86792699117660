import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/index'   ,// 重定向，输入/的时候直接进入首页
		meta:{
			isShow:true,
			title:'品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content:{
				keywords:'品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description:'这里'
			}
		}
	},
	{
		path: '/index',
		name: 'index',
		component: () => import('../views/index/index.vue'),
		meta:{
			isShow:true,
			title:'品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
			content:{
				keywords:'品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
				description:'这里'
			}
		}
	},
	{
		path: '/category',
		name: 'category',
		component: () => import('../views/category/category.vue'),
		meta:{
			isShow:true,
			title:'品牌分类',
		}
		
	},
	{
		path: '/wiki',
		name: 'wiki',
		component: () => import('../views/wiki/wiki.vue')
	},
	{
		path: '/life',
		name: 'life',
		component: () => import('../views/life/life.vue'),
		meta:{
			isShow:true,
			title:'生活排行',
		}
	},
	{
		path: '/service',
		name: 'service',
		component: () => import('../views/service/service.vue')
	},
	{
	  path: '/ranking',
	  name: 'ranking',
	  component: () => import('../views/ranking/ranking.vue')
	},
	{
	  path: '/rankingMore',
	  name: 'rankingMore',
	  component: () => import('../views/rankingMore/rankingMore.vue')
	},
	{
	  path: '/search',
	  name: 'search',
	  component: () => import('../views/search/search.vue')
	},
	{
	  path: '/article',
	  name: 'article',
	  component: () => import('../views/article/article.vue'),
	  meta:{
	  	isShow:true,
	  	title:'品牌百科',
	  }
	},
	{
	  path: '/articleDetail',
	  name: 'articleDetail',
	  component: () => import('../views/article/articleDetail.vue')
	},
	{
	  path: '/citiao',
	  name: 'citiao',
	  component: () => import('../views/article/citiao.vue'),
	  meta:{
	  	isShow:true,
	  	title:'品牌之家 品牌榜 | 十大品牌及生活服务排行榜大数据服务平台',
	  	content:{
	  		keywords:'品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
	  		description:'这里'
	  	}
	  }
	},
	{
	  path: '/brand/detail',
	  name: 'brandDetail',
	  component: () => import('../views/brand/detail.vue')
	},
	{
	  path: '/brand/detail2',
	  name: 'detail2',
	  component: () => import('../views/brand/detail2.vue')
	},
	{
	  path: '/about',
	  name: 'about',
	  component: () => import('../views/about/about.vue'),
	  meta:{
	  	isShow:true,
	  	title:'品牌之家网站介绍',
	  	content:{
	  		keywords:'品牌之家,品牌',
	  		description:'这里'
	  	}
	  }
	},
	{
	  path: '/statement',
	  name: 'statement',
	  component: () => import('../views/statement/statement.vue'),
	  meta:{
	  	isShow:true,
	  	title:'品牌之家免责声明',
	  	content:{
	  		keywords:'品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
	  		description:'这里'
	  	}
	  }
	},
	{
	  path: '/research',
	  name: 'research',
	  component: () => import('../views/research/research.vue'),
	  meta:{
	  	isShow:true,
	  	title:'品牌之家研究院→品牌之家',
	  	content:{
	  		keywords:'品牌之家,研究院',
	  		description:'这里'
	  	}
	  }
	},
	{
	  path: '/contact',
	  name: 'contact',
	  component: () => import('../views/contact/contact.vue'),
	  meta:{
	  	isShow:true,
	  	title:'品牌之家联系方式',
	  	content:{
	  		keywords:'品牌之家,排行榜,品牌,名牌,排名,品牌排行,品牌网,十大品牌,品牌百科,什么牌子好',
	  		description:'这里'
	  	}
	  }
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router
